/*table*/
.table-wrapper {
    box-sizing: border-box;
    overflow: hidden;
    overflow-x: auto;
}
.table-wrapper > table {
    border-collapse: collapse;
    border: 1px solid #ddd;
}
.table-wrapper > table th,
.table-wrapper > table td {
    text-align: left;
    box-sizing: border-box;
    white-space: nowrap;
    font-weight: normal;
    font-size: 11pt;
}
.table-wrapper > table th {
    background-color: rgb(255,76,81);
    padding: 15px 5px;
    color: #fff;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
}

.table-wrapper > table thead tr {
    box-shadow: 0 4px 5px -2px rgba(58,53,65,0.08),0 2px 10px 1px rgba(58,53,65,0.12),0 2px 16px 1px rgba(58,53,65,0.04);
}
.table-wrapper > table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}
.table-wrapper > table tbody tr:hover {
    background-color: #f1e9ea;
}
.table-wrapper > table td {
    padding: 10px 8px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
