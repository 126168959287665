
::selection {
    background: #03a4ed;
    color: #fff;
}

::-moz-selection {
    background: #03a4ed;
    color: #fff;
}


.page-heading {
    background-image: url(~/src/assets/images/heading-bg.webp);
}

.main-white-button a {
    display: inline-block;
    background-color: #fff;
    font-size: 15px;
    font-weight: 400;
    color: #2a2a2a;
    text-transform: capitalize;
    padding: 12px 25px;
    border-radius: 7px;
    letter-spacing: 0.25px;
}

.main-white-button a:hover {
    background-color: #8d99af;
    color: #fff;
}

