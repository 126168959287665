
/*
---------------------------------------------
Footer
---------------------------------------------
*/

footer {
    border-top: 1px solid #eee;
    margin-top: 10px;
    width: 100%;
    padding: 40px 20px;
    background: #fff;
    box-sizing: border-box;
}
footer > .footer-content {
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
    display: flex;
}
footer > .footer-content > section {
    width: 33.3%;
}

footer h2 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin-bottom: 10px;
}

footer p,
footer a {
    color: #000;
}

footer .logo img {
    max-width: 163px;
    margin-bottom: 30px;
}

footer .footer-links ul li {
    display: block;
    margin-bottom: 15px;
}

footer .footer-links ul li a {
    font-size: 15px;
}

footer .footer-links ul li a:hover {
    color: #8d99af;
    border-bottom: 1px solid #8d99af;
}

footer .contact-us p {
    margin-bottom: 25px;
}

footer .contact-us a:hover {
    color: #8d99af;
    border-bottom: 1px solid #8d99af;
}

footer .sub-footer {
    text-align: center;
    margin-top: 40px;
    padding: 40px 0px;
    border-top: 1px solid #eee;
}

@media (max-width: 767px) {
    footer > .footer-content {
        display: block;
    }
    footer > .footer-content > section {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
}

