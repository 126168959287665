.header-area .main-nav a.logo {
    background-image: url(~/src/assets/images/white-logo.webp);
}

.header-area.background-header .main-nav a.logo {
    background-image: url(~/src/assets/images/black-logo.webp);
}

@media (max-width: 767px) {
    .header-area .main-nav a.logo {
        background-image: url(~/src/assets/images/black-logo.webp);
    }
}
