/* montserrat-100 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-100.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-100.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-100italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-100italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-100italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-200 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-200.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-200.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-200italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-200italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-200italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-300 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-300.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-300italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-300italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-regular - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-500 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-500.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-500italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-500italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-500italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-600 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-600italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-600italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-600italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-700 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-700italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-700italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-800 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-800.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-800italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-800italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-800italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-900 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-900.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-900italic - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-900italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('~/src/assets/fonts/montserrat/montserrat-v26-cyrillic_cyrillic-ext_latin-900italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

