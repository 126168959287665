

/*
---------------------------------------------
Recent Listing 
---------------------------------------------
*/

.recent-listing {
    border-top: 1px solid #eee;

    margin: 10px auto;
    width: 100%;
    max-width:1024px;
}

.recent-listing .section-heading {
    text-align: center;
    margin-bottom: 30px;
}


/*
---------------------------------------------
LISTING ITEM
---------------------------------------------
*/


.listing-item {
    display: inline-flex;
    background-color: #f7f3f3;
    border-radius: 7px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    box-sizing: border-box;
}

.left-image {
    cursor: pointer;
    float: left;
    overflow: hidden;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    min-width: 200px;
}

.left-image div.featured-image {
    overflow: hidden;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    width: 100%;
    height: 100%;
    background: #eee;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
}

.right-content {
    display: inline-block;
    padding: 20px !important;
}

.right-content span.title {
    font-size: 20px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 20px;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.right-content p.subject {
    font-size: 15px;
    font-weight: 500;
    color: #5b6370;
    cursor: pointer;
}


.right-content ul.rate {
    position: absolute;
    right: 20px;
    top: 20px;
}

.right-content ul.rate li {
    display: inline-block;
}

.right-content ul.rate li:last-child {
    margin-left: 10px;
}


.right-content span.price {
    color: #2a2a2a;
    font-size: 15px;
    margin-top: 30px;
    display: block;
    margin-bottom: 15px;
}

.right-content span.details {
    display: block;
    margin-bottom: 30px;
    font-size: 15px;
    color: #2a2a2a;
    max-height: 90px;
    overflow: hidden;
    max-width: 90%;
    cursor: pointer;
}

.right-content ul.info li {
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 30px;
    width: 100%;
    box-sizing: border-box;

}

.right-content ul.info li:last-child {
    margin-bottom: 0px;
}

.right-content .main-white-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
}


/* 
---------------------------------------------
Listing Page
--------------------------------------------- 
*/


.listing-page {
    margin: 10px auto;
    width: 100%;
    max-width:1024px;
}

.listing-page .section-heading {
    text-align: center;
    margin-bottom: 80px;
}

.listing-page .listing-pagination {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
}
.listing-page .listing-pagination .listing-pagination-button {
    display: inline-block;
}
.listing-page .listing-pagination .listing-pagination-button a {
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    background-color: #8d99af;
    color: #fff!important;
    text-transform: capitalize;
    padding: 12px 25px;
    border-radius: 7px;
    letter-spacing: 0.25px;
}

.listing-page .listing-item {
    display: inline-flex;
    background-color: #f7f3f3;
    border-radius: 7px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    border: 2px solid #1b1b1b;
}

.listing-page .left-image {
    float: left;
    overflow: hidden;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    position: relative;
    z-index: 1;
}

.listing-page .left-image .hover-content .main-white-button a i {
    font-size: 12px;
    width: 23px;
    height: 23px;
    line-height: 23px;
}

.listing-page .left-image .hover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 11;
    opacity: 0;
    visibility: hidden;
}

.listing-page .listing-item:hover .left-image .hover-content {
    opacity: 1;
    visibility: visible;
}

.listing-page .right-content {
    display: inline-block;
    padding: 45px;
}

.listing-page .right-content h3 {
    font-size: 20px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 20px;
}

.listing-page .right-content p.subject {
    font-size: 15px;
    font-weight: 500;
    color: #5b6370;
}

.listing-page .right-content span.price {
    color: #2a2a2a;
    font-size: 15px;
    margin-top: 30px;
    display: block;
    margin-bottom: 15px;
}

.listing-page .right-content span.details {
    display: block;
    margin-bottom: 30px;
    font-size: 15px;
    color: #2a2a2a;
}

.listing-page .right-content span.info {
    line-height: 30px;
}

/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 1200px) {

    .recent-listing .item .right-content,
    .listing-page .right-content {
        padding: 10px;
        position: relative;
    }
    .recent-listing .item .right-content h3,
    .listing-page .right-content h3 {
        margin-bottom: 10px;
    }
    .recent-listing .item .right-content .main-white-button,
    .listing-page .right-content .main-white-button {
        left: 10px;
        bottom: -30px;
    }
    .recent-listing .item .right-content span.price,
    .listing-page .right-content span.price {
        margin-top: 15px;
    }
    .recent-listing .item .right-content ul.rate,
    .listing-page .right-content ul.rate {
        left: 10px;
        top: -5px;
    }
    .recent-listing .item .right-content span.details,
    .listing-page .right-content span.details {
        margin-bottom: 15px;
    }
}

@media (max-width: 992px) {

    .recent-listing .item .left-image,
    .listing-page .left-image {
        float: none;
        width: 100%;
    }

    .recent-listing .item .left-image,
    .listing-page .left-image {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 7px;
    }
    .recent-listing .item .listing-item,
    .listing-page .listing-item {
        display: inline-block;
    }
    .recent-listing .item .right-content ul.rate,
    .listing-page .right-content ul.rate {
        top: 10px;
    }
    .recent-listing .item .right-content .main-white-button,
    .listing-page .right-content .main-white-button {
        position: relative;
        left: 0px;
        bottom: 0px;
        margin-top: 30px;
    }
    .recent-listing .item .right-content h3,
    .listing-page .right-content h3 {
        margin-top: 20px;
    }
}

