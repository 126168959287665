@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-100.a79af052.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-100.d5081abd.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-100italic.7a3a3cb2.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-100italic.0eb132c5.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-200.e9602330.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-200.da73191b.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-200italic.10eeca7b.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-200italic.4e75d893.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-300.e7cd438d.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-300.3a4079b5.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-300italic.5c84c6cd.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-300italic.0bea69ce.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-regular.f40e473a.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-regular.a4ae7741.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-italic.797edff0.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-italic.a4af2b04.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-500.6e23e32a.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-500.f3c45fce.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-500italic.00afce0b.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-500italic.f223430f.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-600.3c2d51bd.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-600.e7e212ef.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-600italic.bd6a7fd4.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-600italic.187ecccc.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-700.210d8363.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-700.e7a0a846.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 700;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-700italic.398d8266.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-700italic.f6c06cb3.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-800.7711a44b.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-800.e7ec7a20.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-800italic.84c9d0ee.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-800italic.1f1309d8.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-900.6c325c98.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-900.910d4f20.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 900;
  src: url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-900italic.e41fd149.woff2") format("woff2"), url("../fnt/montserrat-v26-cyrillic_cyrillic-ext_latin-900italic.79b4cd16.ttf") format("truetype");
}

::selection {
  color: #fff;
  background: #03a4ed;
}

.page-heading {
  background-image: url("../img/heading-bg.d83545b7.webp");
}

.main-white-button a {
  color: #2a2a2a;
  text-transform: capitalize;
  letter-spacing: .25px;
  background-color: #fff;
  border-radius: 7px;
  padding: 12px 25px;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
}

.main-white-button a:hover {
  color: #fff;
  background-color: #8d99af;
}

.header-area .main-nav a.logo {
  background-image: url("../img/white-logo.6cae9e58.webp");
}

.header-area.background-header .main-nav a.logo {
  background-image: url("../img/black-logo.cbe7664c.webp");
}

@media (width <= 767px) {
  .header-area .main-nav a.logo {
    background-image: url("../img/black-logo.cbe7664c.webp");
  }
}

.main-banner {
  background-image: url("../img/banner-bg.e6aa9d9f.webp");
}

.recent-listing {
  border-top: 1px solid #eee;
  width: 100%;
  max-width: 1024px;
  margin: 10px auto;
}

.recent-listing .section-heading {
  text-align: center;
  margin-bottom: 30px;
}

.listing-item {
  box-sizing: border-box;
  background-color: #f7f3f3;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 30px;
  display: inline-flex;
  position: relative;
}

.left-image {
  cursor: pointer;
  float: left;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  min-width: 200px;
  overflow: hidden;
}

.left-image div.featured-image {
  background: #eee center / contain no-repeat;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  width: 100%;
  height: 100%;
  min-height: 200px;
  overflow: hidden;
}

.right-content {
  display: inline-block;
  padding: 20px !important;
}

.right-content span.title {
  color: #2a2a2a;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  display: block;
}

.right-content p.subject {
  color: #5b6370;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.right-content ul.rate {
  position: absolute;
  top: 20px;
  right: 20px;
}

.right-content ul.rate li {
  display: inline-block;
}

.right-content ul.rate li:last-child {
  margin-left: 10px;
}

.right-content span.price {
  color: #2a2a2a;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 15px;
  display: block;
}

.right-content span.details {
  color: #2a2a2a;
  cursor: pointer;
  max-width: 90%;
  max-height: 90px;
  margin-bottom: 30px;
  font-size: 15px;
  display: block;
  overflow: hidden;
}

.right-content ul.info li {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  margin-right: 30px;
  display: inline-block;
}

.right-content ul.info li:last-child {
  margin-bottom: 0;
}

.right-content .main-white-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.listing-page {
  width: 100%;
  max-width: 1024px;
  margin: 10px auto;
}

.listing-page .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.listing-page .listing-pagination {
  text-align: center;
  width: 100%;
  margin: 20px auto 0;
}

.listing-page .listing-pagination .listing-pagination-button {
  display: inline-block;
}

.listing-page .listing-pagination .listing-pagination-button a {
  text-transform: capitalize;
  letter-spacing: .25px;
  background-color: #8d99af;
  border-radius: 7px;
  padding: 12px 25px;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #fff !important;
}

.listing-page .listing-item {
  background-color: #f7f3f3;
  border: 2px solid #1b1b1b;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 30px;
  display: inline-flex;
  position: relative;
}

.listing-page .left-image {
  float: left;
  z-index: 1;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  position: relative;
  overflow: hidden;
}

.listing-page .left-image .hover-content .main-white-button a i {
  width: 23px;
  height: 23px;
  font-size: 12px;
  line-height: 23px;
}

.listing-page .left-image .hover-content {
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.listing-page .listing-item:hover .left-image .hover-content {
  opacity: 1;
  visibility: visible;
}

.listing-page .right-content {
  padding: 45px;
  display: inline-block;
}

.listing-page .right-content h3 {
  color: #2a2a2a;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}

.listing-page .right-content p.subject {
  color: #5b6370;
  font-size: 15px;
  font-weight: 500;
}

.listing-page .right-content span.price {
  color: #2a2a2a;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 15px;
  display: block;
}

.listing-page .right-content span.details {
  color: #2a2a2a;
  margin-bottom: 30px;
  font-size: 15px;
  display: block;
}

.listing-page .right-content span.info {
  line-height: 30px;
}

@media (width <= 1200px) {
  .recent-listing .item .right-content, .listing-page .right-content {
    padding: 10px;
    position: relative;
  }

  .recent-listing .item .right-content h3, .listing-page .right-content h3 {
    margin-bottom: 10px;
  }

  .recent-listing .item .right-content .main-white-button, .listing-page .right-content .main-white-button {
    bottom: -30px;
    left: 10px;
  }

  .recent-listing .item .right-content span.price, .listing-page .right-content span.price {
    margin-top: 15px;
  }

  .recent-listing .item .right-content ul.rate, .listing-page .right-content ul.rate {
    top: -5px;
    left: 10px;
  }

  .recent-listing .item .right-content span.details, .listing-page .right-content span.details {
    margin-bottom: 15px;
  }
}

@media (width <= 992px) {
  .recent-listing .item .left-image, .listing-page .left-image {
    float: none;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0;
    width: 100%;
  }

  .recent-listing .item .listing-item, .listing-page .listing-item {
    display: inline-block;
  }

  .recent-listing .item .right-content ul.rate, .listing-page .right-content ul.rate {
    top: 10px;
  }

  .recent-listing .item .right-content .main-white-button, .listing-page .right-content .main-white-button {
    margin-top: 30px;
    position: relative;
    bottom: 0;
    left: 0;
  }

  .recent-listing .item .right-content h3, .listing-page .right-content h3 {
    margin-top: 20px;
  }
}

footer {
  box-sizing: border-box;
  background: #fff;
  border-top: 1px solid #eee;
  width: 100%;
  margin-top: 10px;
  padding: 40px 20px;
}

footer > .footer-content {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
}

footer > .footer-content > section {
  width: 33.3%;
}

footer h2 {
  color: #000;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

footer p, footer a {
  color: #000;
}

footer .logo img {
  max-width: 163px;
  margin-bottom: 30px;
}

footer .footer-links ul li {
  margin-bottom: 15px;
  display: block;
}

footer .footer-links ul li a {
  font-size: 15px;
}

footer .footer-links ul li a:hover {
  color: #8d99af;
  border-bottom: 1px solid #8d99af;
}

footer .contact-us p {
  margin-bottom: 25px;
}

footer .contact-us a:hover {
  color: #8d99af;
  border-bottom: 1px solid #8d99af;
}

footer .sub-footer {
  text-align: center;
  border-top: 1px solid #eee;
  margin-top: 40px;
  padding: 40px 0;
}

@media (width <= 767px) {
  footer > .footer-content {
    display: block;
  }

  footer > .footer-content > section {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    display: block;
  }
}

.neworder-form-area {
  background-color: #f7f7f7;
  border-radius: 7px;
  align-items: flex-start;
  max-width: 900px;
  margin: 60px auto;
  padding: 5px;
  display: flex;
  overflow: hidden;
}

@media (width <= 767px) {
  .neworder-form-area {
    display: block;
  }
}

.neworder-form-area h3 {
  margin: 0 0 10px;
  font-size: 16pt;
}

.neworder-form-area p {
  margin-bottom: 10px;
  font-size: 12pt;
}

.neworder-form-area.personal-order .card {
  background: #fff;
  border-radius: 5px;
  min-width: 180px;
  margin-top: 10px;
  padding: 5px;
}

.neworder-form-area.personal-order .card img.card-img-top {
  width: 100%;
  margin: 5px auto 0;
  max-width: 200px !important;
}

.neworder-form-area.personal-order .card .list-group-item {
  font-size: 13pt;
}

form#place-order-form {
  margin: 55px 60px 45px 45px;
  position: relative;
}

form#place-order-form input[type="text"], form#place-order-form input[type="tel"], form#place-order-form input[type="number"] {
  color: #8d99af;
  box-sizing: border-box;
  background-color: #0000;
  border: 1px solid #8d99af;
  border-radius: 7px;
  outline: none;
  width: 100%;
  height: 38px;
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 300;
}

form#place-order-form select {
  color: #8d99af;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #8d99af;
  border-radius: 7px;
  outline: none;
  width: 100%;
  height: 38px;
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 600;
  appearance: auto !important;
}

form#place-order-form input::placeholder {
  color: #8d99af;
  font-weight: 500;
}

form#place-order-form textarea {
  color: #8d99af;
  box-sizing: border-box;
  background-color: #0000;
  border: 1px solid #8d99af;
  border-radius: 7px;
  outline: none;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 140px;
  min-height: 140px;
  max-height: 180px;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px;
  font-size: 15px;
  font-weight: 300;
}

form#place-order-form textarea::placeholder {
  color: #8d99af;
  font-weight: 500;
}

form#place-order-form button {
  color: #fff;
  text-transform: capitalize;
  letter-spacing: .25px;
  background-color: #8d99af;
  border: none;
  border-radius: 7px;
  outline: none;
  margin-bottom: -15px;
  padding: 12px 25px;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
}

form#place-order-form button:hover {
  color: #fff;
  background-color: #2b2d42;
}

.table-wrapper {
  box-sizing: border-box;
  overflow: auto hidden;
}

.table-wrapper > table {
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.table-wrapper > table th, .table-wrapper > table td {
  text-align: left;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: 11pt;
  font-weight: normal;
}

.table-wrapper > table th {
  color: #fff;
  text-shadow: -1px -1px 1px #ffffff1a, 1px 1px 1px #00000080;
  background-color: #ff4c51;
  padding: 15px 5px;
}

.table-wrapper > table thead tr {
  box-shadow: 0 4px 5px -2px #3a354114, 0 2px 10px 1px #3a35411f, 0 2px 16px 1px #3a35410a;
}

.table-wrapper > table tbody tr:nth-child(2n) {
  background-color: #f2f2f2;
}

.table-wrapper > table tbody tr:hover {
  background-color: #f1e9ea;
}

.table-wrapper > table td {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 10px 8px;
}

.pretty-form {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px;
}

.pretty-form fieldset {
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-bottom: 2px solid #ff4c51;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 8px;
  padding: 10px;
  box-shadow: 0 4px 5px -2px #3a354114, 0 2px 10px 1px #3a35411f, 0 2px 16px 1px #3a35410a;
}

.pretty-form fieldset > legend {
  color: #fff;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  text-shadow: -1px -1px 1px #ffffff1a, 1px 1px 1px #00000080;
  background: #ff4c51;
  border-radius: 5px;
  margin: 0;
  padding: 5px 20px;
  box-shadow: 0 4px 5px -2px #3a354114, 0 2px 10px 1px #3a35411f, 0 2px 16px 1px #3a35410a;
}

.pretty-form label {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 6px;
  display: block;
}

.pretty-form label > span {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
}

.pretty-form label > select.form-control, .pretty-form label > input.form-control[type="text"], .pretty-form label > input.form-control[type="tel"], .pretty-form label > input.form-control[type="date"], .pretty-form label > input.form-control[type="email"], .pretty-form label > input.form-control[type="password"], .pretty-form label > input.form-control[type="number"], .pretty-form label > textarea.form-control {
  box-sizing: border-box;
  background-color: #f1f1f1;
  border: none;
  border-radius: 4px;
  outline: 0;
  width: 100%;
  min-width: 140px;
  padding: 5px 10px;
}

.pretty-form label > input.form-control[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  background-color: #fff;
  border-radius: 3px;
  padding: 5px;
}

.pretty-form label.checkbox-holder {
  grid-template-columns: 1em auto;
  gap: .5em;
  display: grid;
}

.pretty-form label.checkbox-holder > input.form-control[type="checkbox"] {
  appearance: none;
  font: inherit;
  color: currentColor;
  cursor: pointer;
  background-color: #fff;
  border: .15em solid;
  border-radius: .15em;
  outline: 0;
  place-content: center;
  width: 1.15em;
  height: 1.15em;
  margin: 0;
  display: grid;
  transform: translateY(-.075em);
}

.pretty-form label.checkbox-holder > input.form-control[type="checkbox"]:before {
  content: "";
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform-origin: 0 100%;
  width: .75em;
  height: .75em;
  transform: scale(0);
  box-shadow: inset 1em 1em #ff4c51;
}

.pretty-form label.checkbox-holder > input.form-control[type="checkbox"]:checked:before {
  transform: scale(1);
}

.pretty-form label.checkbox-holder > input.form-control[type="checkbox"]:disabled {
  color: #000;
  cursor: not-allowed;
}

.btn {
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  margin: 0;
  padding: 5px 30px;
  font-size: 11pt;
  line-height: 20px;
  display: inline-block;
  box-shadow: 0 4px 5px -2px #3a354114, 0 2px 10px 1px #3a35411f, 0 2px 16px 1px #3a35410a;
}

a.btn {
  text-decoration: none !important;
}

.btn.btn-black {
  background: #1b1b1b;
}

.btn.btn-black:hover, .btn.btn-red {
  background: #ff4c51;
}

button.btn:disabled, button.btn:disabled:hover {
  cursor: not-allowed;
  background: #a7999a;
}

@media (width >= 414px) {
  .pretty-form fieldset {
    flex-wrap: wrap;
    display: flex;
  }

  .pretty-form label {
    width: auto;
    padding-right: 10px;
  }
}

@media (width >= 768px) {
  .pretty-form {
    padding: 40px 10px;
  }

  .pretty-form fieldset {
    align-items: center;
  }
}

@media (width >= 992px) {
  .pretty-form {
    padding: 50px 15px;
  }
}

.pretty-form label > img.pretty-form__captcha-image {
  width: 130px;
  height: 50px;
  margin-bottom: 5px;
  display: block;
}

.pretty-form label > a.pretty-form__reload-image-link {
  color: #000;
  text-decoration: underline;
}

.choices {
  margin-bottom: 24px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  background-color: #eaeaea;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin: 0;
  padding: 10px;
  display: block;
}

.choices[data-type*="select-one"] .choices__button {
  opacity: .25;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  border-radius: 10em;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-right: 25px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
}

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}

.choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
  display: none;
}

.choices[data-type*="select-one"]:after {
  content: "";
  pointer-events: none;
  border: 5px solid #0000;
  border-top-color: #333;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  position: absolute;
  top: 50%;
  right: 11.5px;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: #0000 #0000 #333;
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  margin-left: 25px;
  margin-right: 0;
  left: 0;
  right: auto;
}

.choices[data-type*="select-multiple"] .choices__inner, .choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
  opacity: .75;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  border-left: 1px solid #008fa1;
  border-radius: 0;
  width: 8px;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="text"] .choices__button:hover, .choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  vertical-align: top;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  width: 100%;
  min-height: 44px;
  padding: 7.5px 7.5px 3.75px;
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
}

.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  width: 100%;
  padding: 4px 16px 4px 4px;
  display: inline-block;
}

[dir="rtl"] .choices__list--single {
  padding-left: 16px;
  padding-right: 4px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  vertical-align: middle;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  border-radius: 20px;
  margin-bottom: 3.75px;
  margin-right: 3.75px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-left: 3.75px;
  margin-right: 0;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaa;
  border: 1px solid #919191;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 1;
  word-break: break-all;
  will-change: visibility;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-right-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  width: 100%;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.is-active.choices__list--dropdown, .is-active.choices__list[aria-expanded] {
  visibility: visible;
}

.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  border-radius: .25rem .25rem 0 0;
  margin-top: 0;
  margin-bottom: -1px;
  top: auto;
  bottom: 100%;
}

.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  max-height: 300px;
  position: relative;
  overflow: auto;
}

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  padding: 10px;
  font-size: 14px;
  position: relative;
}

[dir="rtl"] .choices__list--dropdown .choices__item, [dir="rtl"] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}

@media (width >= 640px) {
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 100px;
  }

  .choices__list--dropdown .choices__item--selectable:after, .choices__list[aria-expanded] .choices__item--selectable:after {
    content: attr(data-select-text);
    opacity: 0;
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable:after {
    left: 10px;
    right: auto;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  color: gray;
  border-bottom: 1px solid #f7f7f7;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
}

.choices__button {
  text-indent: -9999px;
  appearance: none;
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  vertical-align: baseline;
  background-color: #f9f9f9;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  margin-bottom: 5px;
  padding: 4px 0 4px 2px;
  font-size: 14px;
  display: inline-block;
}

.choices__input:focus {
  outline: 0;
}

.choices__input::-webkit-search-decoration {
  display: none;
}

.choices__input::-webkit-search-cancel-button {
  display: none;
}

.choices__input::-webkit-search-results-button {
  display: none;
}

.choices__input::-webkit-search-results-decoration {
  display: none;
}

.choices__input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.choices__input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

[dir="rtl"] .choices__input {
  padding-left: 0;
  padding-right: 2px;
}

.choices__placeholder {
  opacity: .5;
}
/*# sourceMappingURL=sitestyles.c3897d7e.css.map */
