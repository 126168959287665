.neworder-form-area {
    margin: 60px auto;
    background-color: #f7f7f7;
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    max-width: 900px;
    padding: 5px;
}

@media (max-width: 767px) {
    .neworder-form-area {
        display: block;
    }
}

.neworder-form-area h3 {
    font-size: 16pt;
    margin: 0;
    margin-bottom: 10px;
}
.neworder-form-area p {
    font-size: 12pt;
    margin-bottom: 10px;
}

.neworder-form-area.personal-order .card {
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    min-width: 180px;
}
.neworder-form-area.personal-order .card img.card-img-top {
    width: 100%;
    max-width: 200px !important;
    margin: 0 auto;
    margin-top: 5px;
}
.neworder-form-area.personal-order .card .list-group-item {
    font-size: 13pt;
}

form#place-order-form {
    position: relative;
    margin: 55px 60px 45px 45px;
}


form#place-order-form input[type=text],
form#place-order-form input[type=tel],
form#place-order-form input[type=number] {
    width: 100%;
    height: 38px;
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid #8d99af;
    outline: none;
    font-size: 15px;
    font-weight: 300;
    color: #8d99af;
    padding: 0px 15px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

form#place-order-form select {
    width: 100%;
    height: 38px;
    border-radius: 7px;
    background-color: #fff;
    border: 1px solid #8d99af;
    outline: none;
    font-size: 15px;
    font-weight: 600;
    color: #8d99af;
    padding: 0px 15px;
    margin-bottom: 10px;
    appearance: auto !important;
    box-sizing: border-box;
}

form#place-order-form input::placeholder {
    color: #8d99af;
    font-weight: 500;
}

form#place-order-form textarea {
    margin-top: 10px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 180px;
    min-height: 140px;
    height: 140px;
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid #8d99af;
    outline: none;
    font-size: 15px;
    font-weight: 300;
    color: #8d99af;
    padding: 15px;
    margin-bottom: 30px;
    box-sizing: border-box;
}

form#place-order-form textarea::placeholder {
    color: #8d99af;
    font-weight: 500;
}

form#place-order-form button {
    margin-bottom: -15px;
    display: inline-block;
    background-color: #8d99af;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    padding: 12px 25px;
    border-radius: 7px;
    letter-spacing: 0.25px;
    border: none;
    outline: none;
}

form#place-order-form button:hover {
    background-color: #2b2d42;
    color: #fff;
}
