/* FORMS */

.pretty-form {
    /*background-color: #f4f4f4;*/
    padding: 20px 10px;
    width: 100%;
    box-sizing: border-box;
}

.pretty-form fieldset {
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 8px;
    background: #fff;
    padding: 10px;
    border-bottom: 2px solid rgb(255,76,81);
    box-shadow: 0 4px 5px -2px rgba(58,53,65,0.08),0 2px 10px 1px rgba(58,53,65,0.12),0 2px 16px 1px rgba(58,53,65,0.04);
}
.pretty-form fieldset > legend {
    margin: 0;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 20px;
    user-select: none;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
    background: rgb(255,76,81);
    box-shadow: 0 4px 5px -2px rgba(58,53,65,0.08),0 2px 10px 1px rgba(58,53,65,0.12),0 2px 16px 1px rgba(58,53,65,0.04);
}

.pretty-form label {
    display: block;
    margin-bottom: 6px;
    width: 100%;
    box-sizing: border-box;
}
.pretty-form label > span {
    display: block;
    user-select: none;
    cursor: pointer;
}
.pretty-form label > select.form-control,
.pretty-form label > input.form-control[type=text],
.pretty-form label > input.form-control[type=tel],
.pretty-form label > input.form-control[type=date],
.pretty-form label > input.form-control[type=email],
.pretty-form label > input.form-control[type=password],
.pretty-form label > input.form-control[type=number],
.pretty-form label > textarea.form-control {
    width: 100%;
    box-sizing: border-box;
    min-width: 140px;
    outline: 0;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
}
.pretty-form label > input.form-control[type=date]::-webkit-calendar-picker-indicator {
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}
.pretty-form label.checkbox-holder {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}
.pretty-form label.checkbox-holder > input.form-control[type=checkbox] {
    outline: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
}
.pretty-form label.checkbox-holder > input.form-control[type=checkbox]::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    box-shadow: inset 1em 1em rgb(255,76,81);
}
.pretty-form label.checkbox-holder > input.form-control[type=checkbox]:checked::before {
    transform: scale(1);
}
.pretty-form label.checkbox-holder > input.form-control[type=checkbox]:disabled {
    color: #000;
    cursor: not-allowed;
}

.btn {
    user-select: none;
    color: #fff;
    border: 0;
    display: inline-block;
    margin: 0;
    padding: 5px 30px;
    line-height: 20px;
    font-size: 11pt;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 5px -2px rgba(58,53,65,0.08),0 2px 10px 1px rgba(58,53,65,0.12),0 2px 16px 1px rgba(58,53,65,0.04);
}
a.btn {
    text-decoration: none!important;
}
.btn.btn-black {
    background: #1b1b1b;
}
.btn.btn-black:hover {
    background: rgb(255,76,81);
}
.btn.btn-red {
    background: rgb(255,76,81);
}

button.btn:disabled,
button.btn:disabled:hover {
    cursor: not-allowed;
    background: #a7999a;
}

/* RESPONSIVE */

@media (min-width: 414px) {

    .pretty-form fieldset {
        display: flex;
        flex-wrap: wrap;
    }
    .pretty-form label {
        padding-right: 10px;
        width: auto;
    }
}
@media (min-width: 768px) {

    .pretty-form {
        padding: 40px 10px;
    }
    .pretty-form fieldset {
        align-items: center;
    }

}
@media (min-width: 992px) {

    .pretty-form {
        padding: 50px 15px;
    }
}

/* CAPTCHA */

.pretty-form label > img.pretty-form__captcha-image {
    width: 130px;
    height: 50px;
    display: block;
    margin-bottom: 5px;
}
.pretty-form label > a.pretty-form__reload-image-link {
    color: #000;
    text-decoration: underline;
}
